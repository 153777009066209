export const environment = {
  production: false,
  QA: true,
  PUBLIC_FALLBACK_PAGE: 'auth',
  SITE_KEY: '6LflRi8qAAAAANgR8Zy90EbzvCxxA16B4BsvGAAP',
  CRYPTO_KEY: 'wFqa!3SdG$pX%yMr33zmb=HC@5hN#zG-',
  EXP_COOKIE: 30,
  API_URL: 'https://qa.predictivdata.com:9010/api/',
  PRE_URL: 'https://qa.predictivdata.com/',
  CPM_LOGO_URL: 'https://dzzbs7ion3yxr.cloudfront.net/',
  PRODUCT_NAME: 'DI Chrome Extension',
  IP_LOCATION: 'https://api.my-ip.io/ip.json',
};
